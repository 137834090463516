import { useMutation } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetPolicyMasterListPaging = () => {
	const { data, mutate, isSuccess, isLoading } = useMutation(
		({ policynumber, status }) => {
			return httpClient("policy_master/listPaging", {
				method: "POST",
				data: {
					page: 0,
					size: 0,
					sortBy: "",
					sortOrder: "",
					search: {
						lobId: 0,
						policynumber: policynumber || "",
						bookDateTo: "",
						bookDateFrom: "",
						// status: status || "",
						status: "",
					},
				},
			});
		}
	);

	return { data: data?.data?.data || [], mutate, isSuccess, isLoading };
};

export const useGetPaymentLogsForCustomer = () => {
	const { data, mutate, isLoading } = useMutation({
		mutationFn: (payload) => {
			return httpClient(`recurring_payment_logs/listing `, {
				method: "POST",
				data: payload,
			});
		},
	});
	return { data, mutate, isLoading };
};
