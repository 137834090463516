import React, { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	IconButton,
	Box,
	Stack,
	Chip,
	Drawer,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ICICILombard from "../../../utils/ICICI.png";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import styled from "styled-components";
import CustomRadioButtonGroup from "../../../components/CustomRadioButtonGroup/CustomRadioButtonGroup";
import { useForm } from "react-hook-form";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { isValidURL } from "../../../HelperFunctions/HelperFunctions";
import { useNavigate } from "react-router-dom";
import { MdDownload } from "react-icons/md";
import { MdPayment } from "react-icons/md";
import { useGetCommunicationDataById } from "../../Activity Logs/service";
import { FaLocationArrow, FaRegEdit } from "react-icons/fa";
import { HiPaperAirplane } from "react-icons/hi";
import { useGetPaymentLogsForCustomer } from "../service";
import PaymentLogs from "./PaymentLogs";

const PolicyCardComponent = ({
	policy,
	isRenewalPolicy,
	isRenewalTrackPolicy,
	isClaimHistory,
	setModalDetails,
	isActivityLog,
	isEndorsementHistory,
	isTrackEndorsement,
	allPolicy,
	setModalOpen,
	show,
	status,
	setTrailOpen,
}) => {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const { control, watch } = useForm({
		defaultValues: {
			resolvedchoice: "",
		},
	});
	const isThisResolved = watch("resolvedchoice");

	const lobid = policy?.["lobId"];
	useEffect(() => {
		if (isThisResolved === "no") {
			policy["Policy No"]
				? navigate(`/help?pn=${policy["Policy No"]}`)
				: navigate(`/help`);
		}
	}, [isThisResolved, navigate]);

	const shouldRenderIsThisResolved =
		isClaimHistory ||
		(isEndorsementHistory && isValidURL(policy["Settlement Doc"]));
	const renewalPolicyPdf =
		(isRenewalPolicy || isRenewalTrackPolicy) &&
		isValidURL(policy["Renewal Notice Pdf"]);

	const {
		data: paymentLogsData,
		mutate: mutatePaymentLogs,
		isLoading: isLoadingPaymentLogs,
	} = useGetPaymentLogsForCustomer();
	// const data = useGetCommunicationDataById(policy["Transaction Id"]);

	const payloadForPaymentLogs = {
		page: 0,
		size: 0,
		sortBy: "",
		sortOrder: "",
		paymentLogsSearch: {
			lobId: lobid,
			transactionId: 0,
			month: 0,
			createdTo: "",
			createdFrom: "",
		},
	};

	const renderPolicyDetails = () => {
		return Object.entries(policy).map(([key, value]) => {
			if (
				![
					"id",
					"company",
					"status",
					"Insurer logo",
					"transaction_id",
					"Upload Pdf",
					"businessType",
					"planType",
					"paymentFrequency",
					"Renewal Notice Pdf",
					"stage",
					"Settlement Doc",
					"Ic Name",
					"lobName",
					"Status",
					"claimStage",
					"Transaction Id",
					"Business Type",
					"Payment Frequency",
					"Status",
					"claimStage",
					"lobName",
					"Ic Name",
					"SettlementDocument",
					"Renewal Notice Pdf",
					"Make",
					"lobId",
				].includes(key)
			) {
				const conditionalValue =
					key === "Status"
						? value === "Y"
							? "Yes"
							: value === "N"
							? "No"
							: value
						: value;
				return (
					<Grid
						item
						xs={12}
						sm={3}
						key={key}
						style={{ marginBottom: "6px" }}
					>
						<Box>
							<Typography
								variant="body2"
								style={{
									fontWeight: "600",
									fontSize: "14px",
									color: "#BABABA",
									lineHeight: "30px",
								}}
							>
								{key.charAt(0).toUpperCase() + key.slice(1)}
							</Typography>
							<Typography
								sx={{
									color: "#5D5C5C",
									fontSize: "14px",
									fontWeight: "600",
								}}
							>
								{conditionalValue}
							</Typography>
						</Box>
					</Grid>
				);
			}
			return null;
		});
	};

	return (
		<>
			<CardContainer variant="outlined">
				<CardContent>
					{(isTrackEndorsement || isRenewalPolicy) && (
						<Grid
							container
							spacing={2}
							direction={"row"}
							justifyContent={"space-between"}
							margin={0}
							width={"100%"}
						>
							<FlexBox
								width={"100%"}
								justifyContent={"space-between"}
								padding={"4px 8px"}
								margin={"0px 0px 15px 0px"}
								bg={"#F0F0F0"}
								borderRadius={"4px"}
							>
								<Grid
									item
									xs={"auto"}
									direction={"row"}
									padding={"0px !important"}
								>
									<FlexBox>
										<FlexBox>
											<SubHeading>
												{isRenewalPolicy ? "Renewal ID:" : "Endorsement ID:"}
											</SubHeading>
											<SubHeading color={"#2A2E33"}>
												{policy["Transaction Id"]?.length === 0
													? "NA"
													: policy["Transaction Id"]}
											</SubHeading>
										</FlexBox>
										<FlexBox>
											<SubHeading textAlign={"center"}>LOB:</SubHeading>
											<SubHeading color={"#2A2E33"}>
												{policy["lobName"]?.length === 0
													? "NA"
													: policy["lobName"]}
											</SubHeading>
										</FlexBox>
									</FlexBox>
								</Grid>
								<Grid
									item
									xs={"auto"}
									direction={"row"}
									padding={"0px !important"}
								>
									<FlexBox justifyContent={"end"}>
										<SubHeading>Date Submitted:</SubHeading>
										<SubHeading color={"#2A2E33"}>
											{policy["Policy Expiry date"]?.length === 0
												? "NA"
												: policy["Policy Expiry date"]}
										</SubHeading>
									</FlexBox>
								</Grid>
							</FlexBox>
						</Grid>
					)}
					<Grid
						container
						spacing={2}
						direction={"row"}
						justifyContent={"space-between"}
					>
						<Grid
							item
							xs={4}
							direction={"row"}
						>
							<FlexBox>
								<CardBox width={83.15}>
									<img
										src={policy["Insurer logo"]}
										alt="image"
									/>
								</CardBox>
								<Box>
									<Heading>{policy?.lobName}</Heading>
									<SubHeading>
										Policy No :
										{policy["Policy No"]?.length === 0
											? "NA"
											: policy["Policy No"]}
									</SubHeading>
								</Box>
							</FlexBox>
						</Grid>
						{isTrackEndorsement ? (
							<Grid
								item
								xs={2}
							>
								<FlexBox
									flexDirection={"column"}
									gap={0}
								>
									<SubHeading textAlign={"center"}>
										Changes Requested
									</SubHeading>
									<FlexBox gap={0.4}>
										{policy["Total Premium"]?.length === 0 ? (
											<Heading>N/A</Heading>
										) : (
											<>
												<Heading>&#8377;{policy["Total Premium"]}</Heading>
												{/* <SubHeading small={true}>/year</SubHeading> */}
											</>
										)}
									</FlexBox>
								</FlexBox>
							</Grid>
						) : (
							<>
								<Grid
									item
									xs={2}
								>
									<FlexBox
										flexDirection={"column"}
										gap={0}
									>
										<SubHeading textAlign={"center"}>Premium</SubHeading>
										<FlexBox gap={0.4}>
											{policy["Total Premium"]?.length === 0 ? (
												<Heading>N/A</Heading>
											) : (
												<>
													<Heading>&#8377;{policy["Total Premium"]}</Heading>
													{/* <SubHeading small={true}>/year</SubHeading> */}
												</>
											)}
										</FlexBox>
									</FlexBox>
								</Grid>
								<Grid
									item
									xs={2}
								>
									<FlexBox
										flexDirection={"column"}
										gap={0}
									>
										<SubHeading textAlign={"center"}>
											{["Car", "Bike", "GCV", "PCV"].includes(policy?.lobName)
												? "IDV"
												: "Coverage"}
										</SubHeading>
										<FlexBox gap={0.4}>
											{policy["Coverage"]?.length === 0 ? (
												<Heading>N/A</Heading>
											) : (
												<>
													<Heading>&#8377;{policy["Coverage"]}</Heading>
													{/* <SubHeading small={true}>/year</SubHeading> */}
												</>
											)}
										</FlexBox>
									</FlexBox>
								</Grid>
							</>
						)}
						<Grid
							item
							xs={2}
						>
							<FlexBox
								flexDirection={"column"}
								gap={0}
								// backgroundColor={
								//   policy["expiredays"] < 30 ? "#E1FCEF" : "#FFEDEF"
								// }
								backgroundColor={"#F3F2F2"}
								margin={show ? "0px 26px" : "0px 0px 0px 41px"}
								padding={"3px 0"}
								borderRadius={"8px"}
							>
								<SubHeading textAlign={"center"}>
									{isTrackEndorsement ? "Processed Date" : "Expires on"}
								</SubHeading>
								<Heading textAlign={"center"}>
									{policy["Policy Expiry date"]?.length === 0
										? "N/A"
										: policy["Policy Expiry date"]}
								</Heading>
							</FlexBox>
						</Grid>
						{show && (
							<Grid
								item
								xs={"auto"}
							>
								<ActiveBox status={status}>
									<ActiveCircle status={status}></ActiveCircle>
									<SubHeading color={status ? "#14804A" : "#D1293D"}>
										{policy["Status"]}
									</SubHeading>
								</ActiveBox>
							</Grid>
						)}
					</Grid>
					<FlexBox marginTop={"15px"}>
						<Grid
							container
							spacing={2}
							marginTop={0}
							marginLeft={0}
							direction={"row"}
							gap={"5px 15px"}
						>
							{Array.isArray(policy?.additionalInfo) &&
								policy?.additionalInfo?.map((item, i) => (
									<FlexBox
										key={i}
										item
										xs={"auto"}
									>
										<Heading
											small={true}
											color={"#7A7A7A"}
											lineHeight={"24px"}
										>
											{item} :
										</Heading>
										<Heading
											small={true}
											bold={true}
											lineHeight={"24px"}
										>
											{policy[item]?.length > 16
												? `${policy[item].slice(0, 16)}...`
												: policy[item]?.length === 0
												? "N/A"
												: policy[item]}
										</Heading>
									</FlexBox>
								))}
						</Grid>
						{!isTrackEndorsement ? (
							<Grid
								container
								spacing={2}
								marginTop={0}
								direction={"row"}
								alignContent={"center"}
								justifyContent={"end"}
								// width={isRenewalPolicy ? "86%" : "60%"}
								width={"60%"}
							>
								{allPolicy && (
									<>
										{status && (
											<>
												<PolicyGrid
													item
													xs={"auto"}
													onClick={() =>
														navigate(
															`/raise-claims?TransactionId=${policy?.["Transaction Id"]}&lob=${lobid}`
														)
													}
												>
													<Heading
														small={true}
														textAlign={"center"}
														color={"#4373EB"}
														lineHeight={"24px"}
													>
														Raise Claim
													</Heading>
												</PolicyGrid>
												{/* <PolicyGrid
                        item
                        xs={"auto"}
                        onClick={() =>
                          setModalDetails({
                            title: "Raise an Endorsement",
                            open: true,
                            rowData: policy["Policy No"],
                          })
                        }
                      >
                        <Heading
                          small={true}
                          textAlign={"center"}
                          color={"#4373EB"}
                          lineHeight={"24px"}
                        >
                          Raise Endorsement
                        </Heading>
                      </PolicyGrid> */}
											</>
										)}
										<PolicyGrid
											item
											xs={"auto"}
											onClick={() =>
												window.open(policy["Upload Pdf"], "_blank")
											}
										>
											<FlexBox
												border={"#4373EB"}
												padding={"3px 8px 3px 8px"}
												borderRadius={1}
											>
												<MdDownload color={"#4373EB"} />
												<SubHeading color={"#4373EB"}>
													Download Policy
												</SubHeading>
											</FlexBox>
										</PolicyGrid>
										<PolicyGrid
											item
											xs={"auto"}
											onClick={() => {
												setOpen(true);

												mutatePaymentLogs(payloadForPaymentLogs);
											}}
										>
											<FlexBox
												border={"#4373EB"}
												padding={"3px 8px 3px 8px"}
												borderRadius={1}
											>
												<MdPayment color={"#4373EB"} />
												<SubHeading color={"#4373EB"}>Payment Logs</SubHeading>
											</FlexBox>
										</PolicyGrid>
									</>
								)}
								{/* {(isRenewalPolicy || isEndorsementHistory) && ( */}
								{isRenewalPolicy && (
									<>
										{/* {isRenewalPolicy && ( */}
										<>
											<PolicyGrid
												item
												xs={"auto"}
												onClick={() =>
													window.open(policy["Renewal Notice Pdf"], "_blank")
												}
											>
												<Heading
													small={true}
													textAlign={"center"}
													color={"#4373EB"}
													lineHeight={"24px"}
												>
													Get Renewal Notice PDF
												</Heading>
											</PolicyGrid>
											<PolicyGrid
												item
												xs={"auto"}
											>
												<Heading
													small={true}
													textAlign={"center"}
													color={"#EE5742"}
													lineHeight={"24px"}
												>
													Expiring in {policy["expiredays"]} Days
												</Heading>
											</PolicyGrid>
										</>
										{/* )} */}
										{/* <PolicyGrid
                    item
                    xs={"auto"}
                    onClick={() =>
                      setTrailOpen({
                        open: true,
                        data: policy?.["Policy No"],
                      })
                    }
                  >
                    <Heading
                      small={true}
                      textAlign={"center"}
                      color={"#4373EB"}
                      lineHeight={"24px"}
                    >
                      Communication Trail Log
                    </Heading>
                  </PolicyGrid> */}

										{/* {isEndorsementHistory && (
                    <PolicyGrid
                      item
                      xs={"auto"}
                      onClick={() =>
                        setModalOpen({
                          open: true,
                          data: policy?.["Renewal Notice Pdf"],
                        })
                      }
                    >
                      <FlexBox
                        border={"#4373EB"}
                        padding={"3px 8px 3px 8px"}
                        borderRadius={1}
                      >
                        <MdDownload color={"#4373EB"} />
                        <SubHeading color={"#4373EB"}>Get Settlement Document</SubHeading>
                      </FlexBox>
                    </PolicyGrid>
                  )} */}

										{/* {isRenewalPolicy && ( */}
										<PolicyGrid
											item
											xs={"auto"}
											onClick={() => {
												setModalOpen({
													open: true,
													data: policy,
												});
											}}
										>
											<FlexBox
												border={"#4373EB"}
												padding={"3px 8px 3px 8px"}
												borderRadius={1}
											>
												<SubHeading color={"#4373EB"}>Renew Now</SubHeading>
											</FlexBox>
										</PolicyGrid>
										{/* )} */}
									</>
								)}
							</Grid>
						) : (
							<Grid
								container
								spacing={2}
								marginTop={0}
								direction={"row"}
								alignContent={"center"}
								justifyContent={"end"}
								width={allPolicy ? "50%" : "60%"}
							>
								<PolicyGrid
									item
									xs={"auto"}
									onClick={() =>
										navigate(
											`/raise-claims?TransactionId=${policy?.["Transaction Id"]}&lob=${lobid}`
										)
									}
								>
									<Heading
										small={true}
										textAlign={"center"}
										color={"#4373EB"}
										lineHeight={"24px"}
									>
										View Document
									</Heading>
								</PolicyGrid>
								<PolicyGrid
									item
									xs={"auto"}
									onClick={() =>
										window.open(policy["Settlement Doc"], "_blank")
									}
								>
									<Heading
										small={true}
										textAlign={"center"}
										color={"#4373EB"}
										lineHeight={"24px"}
									>
										Get Settlement Document
									</Heading>
								</PolicyGrid>
								<PolicyGrid
									item
									xs={"auto"}
									onClick={() => window.open(policy["Upload Pdf"], "_blank")}
								>
									<FlexBox
										border={"#4373EB"}
										padding={"3px 8px 3px 8px"}
										borderRadius={1}
									>
										<FaRegEdit color={"#4373EB"} />
										<SubHeading color={"#4373EB"}>Edit Endorsement</SubHeading>
									</FlexBox>
								</PolicyGrid>
							</Grid>
						)}
					</FlexBox>
					{(isTrackEndorsement || isRenewalPolicy) && (
						<>
							<Grid padding={"15px 0px"}>
								<Heading>Recent Communication</Heading>
							</Grid>
							<Grid
								container
								spacing={2}
								direction={"row"}
								justifyContent={"space-between"}
							>
								<Grid
									item
									xs={12}
									direction={"row"}
								>
									<FlexBox justifyContent={"space-between"}>
										<FlexBox
											alignItems={"start"}
											gap={1.4}
										>
											<FlexBox>
												{/* <FaLocationArrow
                        color={"#4373EB"}
                        style={{ transform: "rotate(45deg)" }}
                        fontSize={"20px"}
                      /> */}
												<HiPaperAirplane
													color={"#4373EB"}
													fontSize={"30px"}
													style={{ transform: "rotate(90deg)" }}
												/>
											</FlexBox>
											<Box>
												<Heading>{policy?.lobName}</Heading>
												<SubHeading>
													Policy No :
													{policy["Policy No"]?.length === 0
														? "NA"
														: policy["Policy No"]}
												</SubHeading>
												<ActiveBox status={status}>
													<ActiveCircle status={status}></ActiveCircle>
													<SubHeading color={status ? "#14804A" : "#D1293D"}>
														{policy["Status"]}
													</SubHeading>
												</ActiveBox>
											</Box>
										</FlexBox>
										<PolicyGrid
											item
											xs={"auto"}
											onClick={() =>
												setTrailOpen({
													open: true,
													data: policy?.["Policy No"],
												})
											}
										>
											<Heading
												small={true}
												textAlign={"center"}
												color={"#4373EB"}
												lineHeight={"24px"}
											>
												View Communication History
											</Heading>
										</PolicyGrid>
									</FlexBox>
								</Grid>
							</Grid>
						</>
					)}
				</CardContent>
			</CardContainer>
			<Drawer
				anchor="right"
				open={open}
				onClose={() => setOpen(false)}
			>
				<PaymentLogs
					setOpen={setOpen}
					paymentLogsData={paymentLogsData}
					isLoadingLogs={isLoadingPaymentLogs}
				/>
			</Drawer>
		</>
	);
};

export default PolicyCardComponent;

export const Heading = styled(Box)`
	font-size: ${({ small }) => (small ? "11px" : "14px")};
	color: ${({ color }) => (color ? color : "#011340")};
	// width: 100%;
	font-weight: ${({ small, bold }) => (!bold && small ? "400" : "600")};
`;

export const SubHeading = styled(Box)`
	font-size: ${({ small }) => (small ? "10px" : "11px")};
	color: ${({ color }) => (color ? color : "#7a7a7a")};
	// width: 100%;
	font-weight: ${({ small }) => (small ? "400" : "500")};
`;

const StatusIconContainer = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	svg {
		height: 8px;
		width: 8px;
	}
	p {
		margin: 0px 5px;
	}
`;

export const CardContainer = styled(Card)`
	margin-bottom: 10px;
	border: transparent !important;
	border-radius: 15px;
	box-shadow: 0px 0px 10px 0px #0000001f;
`;

export const CardBox = styled(Box)`
	color: #4373eb;
	font-size: 18px;
	border: 0.7px solid #cacaca;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 45px;
		width: 68px;
		object-fit: contain;
	}
`;

export const FlexBox = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	border: ${({ border }) => (border ? `1px solid ${border}` : "")} !important;
	background-color: ${({ bg }) => (bg ? bg : "")} !important;
`;

export const ActiveBox = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
	background-color: ${({ status }) => (status ? "#E1FCEF" : "#FFEDEF")};
	padding: 1px 8px 1px 8px;
	border-radius: 4px;
`;

export const ActiveCircle = styled.div`
	width: 6px;
	height: 6px;
	background-color: ${({ status }) => (status ? "#14804A" : "#D1293D")};
	border-radius: 2px;
	opacity: 0px;
`;

export const PolicyGrid = styled(Grid)`
	padding-top: 0 !important;
	cursor: pointer;
`;
