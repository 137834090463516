import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Tooltip,
} from "@mui/material";
import styled from "styled-components";
import {
	Close,
	ExpandMore,
	PreviewOutlined,
	FileDownloadRounded,
} from "@mui/icons-material";
import { ActionContainer } from "../../../styles/dataGrid.styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useState } from "react";
import InlineLoader from "../../Dynamic-Claim/InlineLoader";

const PaymentLogs = ({ setOpen, paymentLogsData, isLoadingLogs }) => {
	const [expanded, setExpanded] = useState("panel1");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const paymentLogs = paymentLogsData?.data?.data;
	const paymentYear =
		Array.isArray(paymentLogs) && paymentLogs
			? paymentLogs?.map((item) => item?.createdAt?.slice(0, 4))
			: [];

	const yearsToDisplay = Array.from(new Set(paymentYear));

	const paymentColumns = [
		{
			field: "createdAt",
			headerName: "Payment Date",
			flex: 1,
			background: "#D2F8E9",
		},
		{
			field: "month",
			headerName: "Month",
			flex: 1,
			background: "#D2F8E9",
		},
		{
			field: "paymentFrequency",
			headerName: "Payment Frequency",
			flex: 1,
			background: "#D2F8E9",
		},
		{
			field: "paymentMode",
			headerName: "Payment Mode",
			flex: 1,
			background: "#D2F8E9",
		},
		{
			field: "amountPaid",
			headerName: "Amount",
			flex: 1,
			background: "#D2F8E9",
		},
		{
			field: "Actions",
			headerName: "Actions",
			headerAlign: "left",
			sortable: false,
			width: 100,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				return (
					<ActionContainer>
						<Tooltip
							title="View"
							arrow
						>
							<PreviewOutlined
								id={`view_${params.row.id}`}
								style={{ cursor: "pointer" }}
								index={params.row.id}
								onClick={() => {
									window.open(params.row.paymentReceipt, "_blank");
								}}
							/>
						</Tooltip>
						<Tooltip
							title="Quick Download"
							arrow
						>
							<FileDownloadRounded
								onClick={() => {
									const fileExtn = params.row.paymentReceipt.split(".").pop();
									const link = document.createElement("a");
									link.href = params.row.paymentReceipt;
									link.download = `payment-receipt.${fileExtn}`;
									link.target = "_blank";
									document.body.appendChild(link);
									link.click();
									document.body.removeChild(link);
								}}
							/>
						</Tooltip>
					</ActionContainer>
				);
			},
		},
	];

	const paymentRows =
		Array.isArray(paymentLogs) &&
		paymentLogs?.map((item) => ({
			createdAt: moment(item?.createdAt).format("DD-MM-YYYY"),
			transactionId: item?.transactionId,
			month: item?.month,
			paymentFrequency: item?.paymentFrequency,
			paymentMode: item?.paymentMode,
			amountPaid: item?.amountPaid,
			paymentReceipt: item?.paymentReceipt,
		}));

	return (
		<LogsContainer>
			<Header>
				<Heading>Payment Logs</Heading>
				<IconButton
					size="medium"
					onClick={() => setOpen(false)}
				>
					<Close fontSize="inherit" />
				</IconButton>
			</Header>
			<AccordionContainer>
				{isLoadingLogs && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "14px",
							fontWeight: "700",
							minHeight: "100%",
						}}
					>
						Payment Logs Loading...
						<InlineLoader paymentLogs />
					</Box>
				)}
				{!!paymentRows.length ? (
					yearsToDisplay?.map((year, index) => {
						const gridListRows =
							Array.isArray(paymentRows) &&
							paymentRows
								?.filter((rowItem) => rowItem?.createdAt?.slice(6) === year)
								?.map((item) => ({
									id: item?.transactionId,
									...item,
								}));
						return (
							<Accordion
								key={year}
								expanded={expanded === `panel${index + 1}`}
								onChange={handleChange(`panel${index + 1}`)}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls={`panel${index + 1}-content`}
									id={`panel${index + 1}-header`}
								>
									<LogHeading>Payment Details for Year - {year}</LogHeading>
								</AccordionSummary>
								<AccordionDetails>
									<DetailsContainer>
										<DataGrid
											rows={gridListRows || []}
											columns={paymentColumns}
											hideFooter
											hideFooterSelectedRowCount
											size="small"
											fontSize={12}
											sx={{
												".MuiDataGrid-cellContent": {
													fontSize: "14px",
												},
												".MuiDataGrid-columnSeparator": {
													display: "none",
												},
												"&.MuiDataGrid-root": {
													border: "none",
												},
												".MuiDataGrid-columnHeaders": {
													background: "#F3F2F2",
													color: "#011340",
													fontSize: "14px",
													fontWeight: 500,
												},
												".MuiTablePagination-root p": {
													fontSize: "14px",
												},
											}}
										/>
										<TotalLog>
											Total Payment Logs:
											<LogCount>{gridListRows?.length}</LogCount>
										</TotalLog>
									</DetailsContainer>
								</AccordionDetails>
							</Accordion>
						);
					})
				) : (
					<NoLogs>No Payment Logs Available</NoLogs>
				)}
			</AccordionContainer>
		</LogsContainer>
	);
};

export default PaymentLogs;

const LogsContainer = styled.div`
	width: 75vw;
	height: 100%;
	background: #fff;
	border-radius: 10px;
	padding: 25px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	margin-left: 10px;
`;

const Heading = styled.h2`
	margin: 0;
	display: flex;
	color: #011340;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
`;

const LogHeading = styled.h3`
	margin: 0 0 0 10px;
	display: flex;
	color: #011340;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
`;

const AccordionContainer = styled.div`
	width: 100%;
	height: 93%;
	overflow-y: auto;
	border-radius: 10px;
	border: 0.75px solid #e4e4e4;
`;

const NoLogs = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
`;

const DetailsContainer = styled.div`
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: 0.75px solid #e4e4e4;
	border-radius: 10px;
`;

const TotalLog = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 10px 20px 10px 0;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
`;

const LogCount = styled.span`
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	margin-left: 3px;
`;
